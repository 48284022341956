import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ContactForm from '../../utilities/ContactForm/ContactForm';
import './Contacts.scss';

class Contact extends React.Component {

    render() {
        return(
            <HelmetProvider>  
                <Helmet>
                    <title>Контакты — yoso.ru</title>
                </Helmet>
                <div className="contacts">
                    <div className="contacts__text-block">
                        <p className="contacts__title">Контакты</p>
                        <p className="contacts__text">
                            Мы свяжемся с вами и подробно ответим на вопросы о наших продуктах и услугах.
                        </p>
                    </div>

                    <div className="contacts__form-block">
                        <ContactForm modalForm={false} />
                    </div>
                </div>
            </HelmetProvider>
        )
    }
}

export default Contact;