import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Switcher from '../common_blocks/Switcher/Switcher';
import FrontPage from '../common_blocks/FrontPage/FrontPage';
import LazyImage from '../../../../utilities/LazyImage/LazyImage';

import './H2U.scss';

import logo_hydrogen from '../../../../images/h2u_logo_white.svg';
import macbook_hydrogen from '../../../../images/macbook_hydrogen.png';
import h2u_logotip from '../../../../images/hydrogen_logotip_h2u.png';
import water_logotip from '../../../../images/hydrogen_logotip_water.png';
import past_img1 from '../../../../images/hydrogen_past_img1.png';
import past_img2 from '../../../../images/hydrogen_past_img2.png';
import past_img3 from '../../../../images/hydrogen_past_img3.png';
import past_img4 from '../../../../images/hydrogen_past_img4.png';
import newstyle_img1 from '../../../../images/hydrogen_newstyle_img1.png';
import newstyle_img2 from '../../../../images/hydrogen_newstyle_img2.png';
import newstyle_img3 from '../../../../images/hydrogen_newstyle_img3.png';
import newstyle_img4 from '../../../../images/hydrogen_newstyle_img4.png';
import newstyle_img5 from '../../../../images/hydrogen_newstyle_img5.png';
import newstyle_img6 from '../../../../images/hydrogen_newstyle_img6.png';
import newstyle_img7 from '../../../../images/hydrogen_newstyle_img7.png';



function H2U() {
    return(
        <HelmetProvider>
            <Helmet>
                <title>H2U — yoso.ru</title>
            </Helmet> 
            <div className="hydrogen-main">
                <div className="hydrogen-main__info">
                    <img className="hydrogen-main__logo" src={logo_hydrogen} alt="Логотип H2U" />
                    <a 
                        href="https://h2u.ru/" 
                        className="hydrogen-main__link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        h2u.ru
                    </a>
                </div>
            
                <div className="hydrogen-main__tablet">
                    <img 
                        className="hydrogen-main__image" 
                        src={macbook_hydrogen} 
                        alt="Ноутбук с открытой страницей сайта по продаже генераторов водорода и ионизаторов из Кореи" />
                </div>
            </div>
            <FrontPage />
             
            <div className="hydrogen-content">
                <div className="hydrogen-goal__text-block">
                        <p className="hydrogen-goal__title">Задача</p>
                        <p className="hydrogen-goal__text">
                            Крупный поставщик аппаратов для производства ионизированной воды 
                            в домашних условиях хотел произвести полный ребрендинд магазина. 
                            В рамках задачи мы разработали новый логотип и дизайн сайта.
                        </p> 
                </div>
                <div className="hydrogen-logo">
                    <div className="hydrogen-logo__text-block">
                        <p className="hydrogen-logo__title">Логотип</p>
                    </div>
                    <div className="hydrogen-logo__image-block">
                        <div>
                            <LazyImage 
                                className="hydrogen-logo__image1" 
                                src={water_logotip} 
                                alt="Предыдущий логотип магазина ионизаторов воды" />
                            <p className="hydrogen-logo__text">Было</p>
                        </div>
                        <div>
                            <LazyImage 
                                className="hydrogen-logo__image2" 
                                src={h2u_logotip} 
                                alt="Новый логотип магазина ионизаторов воды H2U" />
                            <p className="hydrogen-logo__text">Стало</p>
                        </div>
                    </div>
                </div>
        
                <div className="hydrogen-past">
                    <div className="hydrogen-past__text-block">
                        <p className="hydrogen-past__title">Как было</p>
                        <p className="hydrogen-past__text">
                            Заказчику не нравился дизайн прошлого интрнет магазина. 
                            Также было желание отказаться от платформы wordpress.
                        </p>
                    </div>
                </div>
                <div className="hydrogen-past__image-block">
                    <LazyImage 
                        className="hydrogen-past__img1" 
                        src={past_img1} 
                        alt="Старый дизайн магазина по продаже генераторов водорода" />
                    <LazyImage 
                        className="hydrogen-past__img2" 
                        src={past_img2}
                        alt="Старый дизайн магазина H2U c товаром Генератор воды Biontech BTH-101T" />
                    <LazyImage 
                        className="hydrogen-past__img3" 
                        src={past_img3} 
                        alt="Жидкий pH тестер по цене 500 руб. Красный флакон" />
                    <LazyImage 
                        className="hydrogen-past__img4" 
                        src={past_img4} 
                        alt="Форма связи с магазином по продаже ионизаторов" />
                </div>
                
                <div className="hydrogen-newstyle">
                    <div className="hydrogen-newstyle__text-block">
                        <p className="hydrogen-newstyle__title">Новый стиль</p>
                        <p className="hydrogen-newstyle__text">
                            В оформлении сайта необходимо было использовать красный, черный и белый цвета, 
                            чтобы магазин вызывал ассоциации с Японией и Кореей, которые производят наиболее 
                            качественное оборудование. Также требовалось максимально упростить дизайн, 
                            чтобы удешевить процесс разработки в будущем.
                        </p>
                    </div>
                </div>
                <div className="hydrogen-newstyle__image-block">
                    <LazyImage 
                        className="hydrogen-newstyle__img1" 
                        src={newstyle_img1} 
                        alt="Новый дизайн магазина по продаже ионизаторов с формой для оформления заказа" />
                    <LazyImage 
                        className="hydrogen-newstyle__img2" 
                        src={newstyle_img2} 
                        alt="Настольный генератор водородной воды CT-170 характеристики" />
                    <LazyImage 
                        className="hydrogen-newstyle__img4" 
                        src={newstyle_img4} 
                        alt="Заказ на покупку ионизатора на сайте H2U оформлен" />
                    <LazyImage 
                        className="hydrogen-newstyle__img5" 
                        src={newstyle_img5} 
                        alt="Инормация о доставке, оплате и установке ионизаторов и генераторов водорода" />
                    <LazyImage 
                        className="hydrogen-newstyle__img6" 
                        src={newstyle_img6} 
                        alt="Контакты и адреса магазина H2U в разных городах России" />
                    <LazyImage 
                        className="hydrogen-newstyle__img7" 
                        src={newstyle_img7} 
                        alt="Статья об использовании ионизаторов воды и пользе генераторов водорода" />
                    <LazyImage 
                        className="hydrogen-newstyle__img3" 
                        src={newstyle_img3} 
                        alt="Основная страница магазина H2U с полным перечнем товаров" />
                </div>
            </div>
            <Switcher prev="/portfolio/portfolio_collegeBeast" next="/portfolio/portfolio_redKey" />
        </HelmetProvider>
    )
};

export default H2U;