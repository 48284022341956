import React from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';

import './ContactForm.scss';
import config from '../../config.json';

const hostname = config.hostname;


class ContactForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            phone: '',
            position: '',
            email: '',
            company: '',
            info: '',
            errors: {
                email: null,
                username: null
            },
            isSent: false,
            alertMessage: '',
            showAlert: true,
            isSending: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.errors.username === ""
            && this.state.errors.email === ""){
                this.sendForm();
            }
        else {
            this.validateEmail();
            this.validateName();
        }
    }

    sendForm = async () => {
        this.setState({
            ...this.state,
            isSending: true
        });

        const {   
            username,
            phone,
            position,
            email,
            company,
            info,
        } = this.state;
        let showError = false;
        
        const response = await fetch(`https://${hostname}/sendMail`, { 
            method: 'POST', 
            headers: { 
                'Content-type': 'application/json'
            }, 
            body: JSON.stringify({
                username, 
                phone, 
                position, 
                email, 
                company, 
                info
            }) 
        }).catch (function(error) {
            showError = error;
        })
        
        if (showError){
            this.handleAlert(false);
        }

        if (response){
            const resData = await response.json(); 

            if (resData.status === 'success'){
                this.resetForm();
                if (this.props.modalForm){
                    this.props.onHide();
                }
                this.handleAlert(true);
            } else if(resData.status === 'fail'){
                this.handleAlert(false);
            }
        }

        this.setState({
            ...this.state,
            isSending: false
        });
    }

    resetForm = () => {
        this.setState({
            username: '',
            phone: '',
            position: '',
            email: '',
            company: '',
            info: '',
            errors: {
                email: null,
                username: null
            },
            isSent: false,
            alertMessage: '',
            showAlert: true
        });
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, () => {
            switch(name){
                case 'username':
                    this.validateName();
                    break;
                case 'email':
                    this.validateEmail();
                    break;
                default:
                    break;
            }
        })
      }
    
    validateName = () => {
        const nameValid = this.state.username.length >= 3;
        const nameError = nameValid ? "" : 'Минимальное количество символов: 3';
        setTimeout(() => {
            this.setState({
                ...this.state, 
                errors: {
                    ...this.state.errors,
                    username: nameError
                }
            })
        }, 10)
    }
    
    validateEmail = () => {
        const value = this.state.email;
        let emailValid = value.length >= 3;
        let emailError = emailValid ? "" : 'Минимальное количество символов: 3';
        if (emailValid){
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            emailError = emailValid ? "" : 'Введите правильные данные';
        }
        setTimeout(() => {
            this.setState({
                ...this.state, 
                errors: {
                    ...this.state.errors,
                    email: emailError,
                }
            })
        }, 10)    
    }

    closeEvent = () =>{
        this.props.onHide();
        this.resetForm();
    }

    handleAlert = (messageSent) => {
        const displayMessage = messageSent ? 
        "Сообщение отправлено. Мы свяжемся с Вами в ближайшее время!" :
        "Ошибка отправки. Повторите попытку!"
                                               
        this.setState({
            ...this.state,
            isSent: messageSent,
            alertMessage : displayMessage
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            showAlert: !this.state.showAlert
        })
    }
    
    render() {
        const itemStyle = this.props.modalForm ? "contacts__form-modal-item" : "contacts__form-item";
        return(
            <>  
                {this.state.showAlert && (
                    <Alert 
                        variant={this.state.isSent ? "success" : "warning"}
                        className={this.state.alertMessage ? "" : "contacts__alert"}
                        onClose={this.handleClose}
                        dismissible>
                            {this.state.alertMessage}
                    </Alert>
                )}
                <Form 
                    onSubmit={this.handleSubmit} 
                    noValidate>
                    <div className="contacts__form-body">
                        <Form.Group className={itemStyle} controlId="name">
                            <Form.Label
                                className={this.state.errors.username ? "contacts__label-error" :
                                "contacts__form-label"}>
                                Как Вас зовут?
                                <span className="contacts__label-error"> *</span>
                            </Form.Label>
                            <Form.Control 
                                className={this.state.errors.username ? "contacts__control-error" :
                                "contacts__form-control"}
                                type="text" 
                                name="username" 
                                placeholder="Иванов Иван Иванович"
                                onChange={this.handleUserInput}
                                onBlur={this.validateName}
                                value={this.state.username}
                            />
                            <div className="contacts__error">
                                {this.state.errors.username}
                            </div>
                        </Form.Group>
                        <Form.Group className={itemStyle} controlId="phoneNumber">
                            <Form.Label className="contacts__form-label">Контактный телефон</Form.Label>
                            <Form.Control 
                                className="contacts__form-control" 
                                type="tel" 
                                name="phone" 
                                placeholder="+7 111 111 1111"
                                maxLength="18"
                                onChange={this.handleUserInput}
                                value={this.state.phone}
                                />
                        </Form.Group> 
                        <Form.Group className={itemStyle} controlId="position">
                            <Form.Label className="contacts__form-label">Ваша должность</Form.Label>
                            <Form.Control className="contacts__form-control"
                                type="text" 
                                name="position"
                                placeholder=" "
                                onChange={this.handleUserInput}
                                value={this.state.position} />
                        </Form.Group>
                        <Form.Group className={itemStyle} controlId="email">
                            <Form.Label 
                                className={this.state.errors.email ? "contacts__label-error" :
                                "contacts__form-label"}>
                                Контактный email
                                <span className="contacts__label-error"> *</span>
                            </Form.Label>
                            <Form.Control 
                                className={this.state.errors.email ? "contacts__control-error" :
                                "contacts__form-control"}
                                type="email" 
                                name="email" 
                                placeholder="example@email.ru"
                                onChange={this.handleUserInput}
                                onBlur={this.validateEmail}
                                value={this.state.email} />
                                <div className="contacts__error">
                                    {this.state.errors.email}
                                </div>
                        </Form.Group>
                        <Form.Group className={itemStyle} controlId="company">
                            <Form.Label className="contacts__form-label">Какую компанию представляете?</Form.Label>
                            <Form.Control 
                                className="contacts__form-control"
                                type="text" 
                                name="company"
                                placeholder=" "
                                onChange={this.handleUserInput}
                                value={this.state.company} />
                        </Form.Group>
                        <Form.Group className={itemStyle} controlId="info">
                            <Form.Label className="contacts__form-label">Какую информацию Вам предоставить?</Form.Label>
                            <Form.Control 
                                className="contacts__form-control" 
                                as="textarea" 
                                rows="3" 
                                name="info"
                                placeholder=" "
                                onChange={this.handleUserInput}
                                value={this.state.info}  />
                        </Form.Group>
                    </div>
                    <div className="contacts__form-button-block">
                            {this.props.modalForm 
                            ?
                                <Button
                                    className="bem-button close-button"
                                    onClick={() => this.closeEvent()}
                                >
                                    <span className="bem-button__text close-button__text">Закрыть</span>
                                </Button> 
                            :
                                <div/>
                            }
                            
                        <Button 
                            className="bem-button contacts__form-button"
                            type="submit"
                            disabled={this.state.isSending}
                        >   
                        {this.state.isSending 
                            ? (
                                <Spinner
                                    className="contacts__spinner"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    aria-hidden="true"
                                />
                            )
                            : (<span className="bem-button__text">Отправить</span>)
                        }

                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}

export default ContactForm;
