import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ModalInfo from '../Modal/Modal';
import LazyImage from '../../utilities/LazyImage/LazyImage';
import './Products.scss';

import main_tablet from '../../images/products_main_tablet.png';
import media_micro from '../../images/products_media_micro.png';
import media_paper from '../../images/products_media_paper.png';
import media_truck from '../../images/products_media_truck.png';
import functions_optimization from '../../images/products_functions_optimization.png';
import functions_time from '../../images/products_functions_time.png';
import functions_mobile from '../../images/products_functions_mobile.png';
import functions_monitoring from '../../images/products_functions_monitoring.png';
import functions_integration from '../../images/products_functions_integration.png';

function Products() {
    const [modalShow, setModalShow] = useState(false);

    return(
        <HelmetProvider>
            <Helmet>
                <title>Автоматизация логистики — yoso.ru</title>
            </Helmet>
            <div className="products-main-bg">
                <div className="products-main">
                    <div className="products-main__info-block">
                        <div className="products-main__title-outer">
                            <p className="products-main__title">
                                <span className="products-main__title-bold">Yoso.Logistics<br/></span>
                                <span className="products-main__title-normal">управление доставкой и оптимизация маршрутов</span>
                            </p>
                        </div>
                        <div className="products-main__button-block">
                            <Button className="bem-button products-main__button" onClick={() => setModalShow(true)}>
                                <span className="bem-button__text">заказать презентацию</span>
                            </Button>
                        </div>
                    </div>
                    <div className="products-main__media-block">
                        <img className="products-main__tablet" src={main_tablet} alt="Планшет с картой и планом рейсов водителя, доставляющего воду" />
                    </div>
                </div>
            </div>
            <div className="products-media-bg">
                <div className="products-media">
                    <div className="products-media__item">
                        <LazyImage className="products-media__img" src={media_paper} alt="Иконка руки, которая держит документ" />
                        <span className="products-media__text">Оптимизация маршрутов</span>
                    </div>
                    <div className="products-media__item">
                        <LazyImage className="products-media__img" src={media_micro} alt="Иконка динамика, громкоговорителя" />
                        <span className="products-media__text">Оповещение клиентов о времени доставки</span>
                    </div>
                    <div className="products-media__item">
                        <LazyImage className="products-media__img" src={media_truck} alt="Иконка машины с изображенным на ней символом геопозиции" />
                        <span className="products-media__text">Отображение положения курьера на карте</span>
                    </div>
                </div>
            </div>
            <div className="products-functions">
                <div>
                    <div className="products-optimization">
                        <div className="products-optimization__text-block">
                            <p className="products-optimization__title">Оптимизация и визуализация маршрутов</p>
                            <p className="products-optimization__text">
                                Загрузите в приложение информацию о заказах и автомобилях, которые будут их развозить.
                                <br/><br/>
                                Система построит оптимальные маршруты для всех автомобилей и отобразит результат на карте. 
                                При необходимости вы сможете отредактировать построенный маршрут или перераспределить заказы между водителями.
                            </p>
                        </div>
                        <div className="products-optimization__image-block">
                            <LazyImage 
                                className="products-functions__img" 
                                src={functions_optimization} 
                                alt="Карта Казани с изображением маршрутов и план рейсов" />
                        </div>
                    </div>
                </div>

                <div className="products-functions__bg">
                    <div className="products-time">
                        <div className="products-time__text-block">
                            <p className="products-time__title">Расчет времени доставки и уведомление клиентов</p>
                            <p className="products-time__text">
                                Система рассчитает время прибытия автомобиля в точку доставки и уведомит о нем клиента посредством СМС. 
                                <br/><br/>
                                Не все водители ездят одинаково, поэтому у вас будет возможность 
                                скорректировать расчет для каждого из них, настроив коэффициенты опытности.
                            </p>
                        </div>
                        <div className="products-time__image-block">
                            <LazyImage 
                                className="products-functions__img" 
                                src={functions_time} 
                                alt="Карта Казани с планом рейсов и мобильное приложение с сообщением о доставке" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="products-mobile">
                        <div className="products-mobile__text-block">
                            <p className="products-mobile__title">Мобильное приложение курьера</p>
                            <p className="products-mobile__text">
                                Приложение курьера позволяет отказаться от бумажных маршрутых листов. 
                                <br/><br/>
                                Курьер сможет:<br/> 
                                - просматривать маршрут, отсортированный в оптимальном порядке <br/>
                                - просмотреть детальную информацию о заказе с возможностью позвонить клиенту прямо из приложения<br/>
                                - зафиксировать статус доставки и внести в заказ необходимые корректировки<br/> 
                                - принять оплату посредством QR-кода<br/> 
                                - просмотреть статистику рейса<br/>
                                <br/>
                                Все изменения заказов регистируются в системе и выводятся в простом отчёте, а также выгружаются в вашу учётную систему.
                            </p>
                        </div>
                        <div className="products-mobile__image-block">
                            <LazyImage 
                                className="products-functions__img"    
                                src={functions_mobile} 
                                alt="Мобильное приложение, в котором описание деталей заказа доставки воды" />
                        </div>
                    </div>
                </div>

                <div className="products-functions__bg">
                    <div className="products-monitoring">
                        <div className="products-monitoring__text-block">
                            <p className="products-monitoring__title">Мониторинг работы водителя</p>
                            <p className="products-monitoring__text">
                                Результаты работы водителя, а также его местоположение регистрируются 
                                в системе в реальном времени. Вы в любой момент можете узнать 
                                где находится автомобиль и какой заказ сейчас выполняется.
                            </p>
                        </div>
                        <div className="products-monitoring__image-block">
                            <LazyImage 
                                className="products-functions__img" 
                                src={functions_monitoring} 
                                alt="Карта с оптимальными маршрутами доставки" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="products-integration">
                        <div className="products-integration__text-block">
                            <p className="products-integration__title">Интеграция с вашей учетной системой</p>
                            <p className="products-integration__text">
                                Благодаря интеграции с учетными системами (1С, CRM и другими) вы минимизируете ручной труд и человеческий фактор.
                                <br/><br/>
                                Интеграцию с вашей учетной системой наша компания сделает бесплатно.
                            </p>
                        </div>
                        <div className="products-integration__image-block">
                            <LazyImage 
                                className="products-integration__img"
                                src={functions_integration}
                                alt="Логотип 1С + yoso" />
                        </div>
                    </div>
                </div>
            </div>
            <ModalInfo show={modalShow} onHide={() => setModalShow(false)}/>
        </HelmetProvider>
    )
}

export default Products;