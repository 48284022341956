import React from "react";
import LazyLoad from "react-lazyload";

import './LazyImage.scss';

const LazyImage = ({src, alt, className}) => {
    const refPaceholder = React.useRef();

    const removePlaceholder = () => {
        refPaceholder.current.remove();
    }

    return (
        <>
            <div>
                <div 
                    ref={refPaceholder}
                    // className="placeholder" 
                    // style={{
                    //         width: width,
                    //         height: height,
                    //         }}
                > 
                </div>
                <LazyLoad offset={100}>
                    <img
                        onLoad={removePlaceholder}
                        onError={removePlaceholder}
                        src={src}
                        alt={alt}
                        className={className}
                    />
                </LazyLoad>
            </div>
        </>
    )
}

export default LazyImage;