import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ModalInfo from '../../../../Modal/Modal';
import LazyImage from '../../../../../utilities/LazyImage/LazyImage';

import './FrontPage.scss';

import upper_union from '../../../../../images/sestrica-upper_union.png';
import lower_union from '../../../../../images/sestrica-lower_union.png';

function FrontPage() {
    const [modalShow, setModalShow] = useState(false);

    return(
        <>
            <div className="contact-comp">
                <LazyImage 
                    className="contact-comp__upper-union"
                    src={upper_union}
                    alt="синие полоски для декорации сайта" 
                />
                <LazyImage 
                    className="contact-comp__lower-union"
                    src={lower_union}
                    alt="синий декоративный дизайн на фоне" 
                />
                <div className="contact-comp__info-block">
                    <p className="contact-comp__text">Хотите превратить свой проект в реальность?</p>
                    <Button className="bem-button" onClick={() => setModalShow(true)}>
                        <span className="bem-button__text">Связаться с нами</span>
                    </Button>
                </div>
            </div>
            <ModalInfo show={modalShow} onHide={() => setModalShow(false)}/>
        </>
    )
}

export default FrontPage;