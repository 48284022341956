import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FrontPage from '../common_blocks/FrontPage/FrontPage';
import Switcher from '../common_blocks/Switcher/Switcher';
import Alert from '../common_blocks/Alert/Alert';
import LazyImage from '../../../../utilities/LazyImage/LazyImage';

import './Sestrica.scss';
import '../common_blocks/FrontPage/FrontPage.scss';

import logo_sestrica from '../../../../images/sestrica_logo_white.svg';
import macbook_sestrica from '../../../../images/macbook_sestrica.png';
import sestrica_youtube from '../../../../images/sestrica_youtube.png';
import past_image from '../../../../images/sestrica_past_image.png';
import design_image1 from '../../../../images/sestrica_design_image1.png';
import design_image2 from '../../../../images/sestrica_design_image2.png';
import design_image3 from '../../../../images/sestrica_design_image3.png';
import design_image4 from '../../../../images/sestrica_design_image4.png';
import design_image5 from '../../../../images/sestrica_design_image5.png';
import adaptive_image from '../../../../images/sestrica_adaptive_image.png';
import mobile_phone from '../../../../images/sestrica_mobile_phone.png';
import mobile_appstore from '../../../../images/sestrica_mobile_appstore.png';
import mobile_playmarket from '../../../../images/sestrica_mobile_playmarket.png';

function SestricaComponent() {

    return(
        <HelmetProvider>
            <Helmet>
                <title>Сестрица — yoso.ru</title>
            </Helmet> 
            <div className="sestrica-main">
                <div className="sestrica-main__info">
                    <img src={logo_sestrica} alt="Логотип интернет магазина Сестрица" />
                    <p className="sestrica-main__text">Вода "Сестрица"</p>
                    <a 
                        href="https://voda-sestrica.ru/" 
                        className="sestrica-main__link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        voda-sestrica.ru
                    </a>
                </div>
                <div className="sestrica-main__tablet">
                    <img 
                        className="sestrica-main__image"
                        src={macbook_sestrica} 
                        alt="Ноутбук с главной страницей сайта по доставке воды" />
                </div>
            </div>
            <FrontPage />
             
            <div className="sestrica-goal">
                <div className="sestrica-goal__text-block">
                    <p className="sestrica-goal__title">Задача</p>
                    <p className="sestrica-goal__text">
                        Реализовать новый интернет-магазин для крупного поставщика природной питьевой воды из Марий Эл. 
                        Сместить акцент с имиджевого контента на коммерческий, реализовать новый функционал.
                    </p>
                </div>
                <div className="sestrica-goal__image-block">
                    <LazyImage 
                        className="sestrica-goal__image" 
                        src={sestrica_youtube} 
                        alt="Youtube видео о производственно-логистическом комплексе Сестрица" />  
                </div>
            </div>

            <div className="sestrica-past">
                <div className="sestrica-past__text-block">
                    <p className="sestrica-past__title">Как было</p>
                    <p className="sestrica-past__text">
                        Прошлый сайт не удовлетворял заказчика большим количеством имиджевого контента, 
                        обилием ошибок и сложностью поддержки и развития проекта на 1C-Bitrix.
                    </p>
                </div>
                <div className="sestrica-past__image-block">
                    <LazyImage
                        className="sestrica-past__img"
                        src={past_image}
                        alt="Старый дизайн интернет-магазина по доставке воды"
                    />
                </div>
                <Alert />
            </div>

            <div className="sestrica-design">
                <div className="sestrica-design__text-block">
                    <p className="sestrica-design__title">Дизайн</p>
                    <p className="sestrica-design__text">
                        Основное требование заказчика - сделать интернет 
                        более простым и дружелюбным для пользователя
                    </p>
                </div>
                <div className="sestrica-design__image-block">
                    <LazyImage 
                        className="sestrica-design__image1" 
                        src={design_image1} 
                        alt="Новый дизайн интернет-магазина Сестрица. Природная вода 19л" />
                    <LazyImage 
                        className="sestrica-design__image3" 
                        src={design_image3} 
                        alt="Темно-синий квадрат с закругленными углами" />
                    <LazyImage 
                        className="sestrica-design__image2" 
                        src={design_image2} 
                        alt="Основная страница обновленного сайта Сестрица" />
                    <LazyImage 
                        className="sestrica-design__image4" 
                        src={design_image4} 
                        alt="Форма авторизации на новом сайте Сестрицы" />
                    <LazyImage 
                        className="sestrica-design__image5" 
                        src={design_image5} 
                        alt="Форма оформления заказа природной воды из магазина Сестрица" />
                </div>
            </div>

            <div className="sestrica-adaptive">
                <div className="sestrica-adaptive__text-block"> 
                    <p className="sestrica-adaptive__title">Адаптив</p>
                    <p className="sestrica-adaptive__text">
                        Больше половины пользователей сайта заходят на сайт с мобильных устройств. 
                        Поэтому особый упор был сделан на адаптивность.
                    </p>
                </div>
                <div className="sestrica-adaptive__image-block">
                    <LazyImage 
                        className="sestrica-adaptive__image" 
                        src={adaptive_image} 
                        alt="Мобильное приложение магазина доставки воды" />
                </div>
            </div>

            <div className="sestrica-mobile">
                <div className="sestrica-mobile__text-block">
                    <p className="sestrica-mobile__title">Мобильное приложение</p>
                    <p className="sestrica-mobile__text-info">
                        Кроме сайта мы разработали мобильное приложение. Приложение было 
                        сделано кроссплатформенным с одинаковым функционалом и дизайном
                    </p>
                </div>
                <div className="sestrica-mobile__media-block">
                    <div>
                        <p className="sestrica-mobile__text-download">Скачать приложение</p>
                        <div className="sestrica-mobile__button-block">
                            <a href="https://www.apple.com/ru/ios/app-store/" target="_blank" rel="noopener noreferrer">
                                <LazyImage 
                                    className="sestrica-mobile__appstore" 
                                    src={mobile_appstore} 
                                    alt="Скачать приложение Сестрица в AppStore" />
                            </a>
                            <a href="https://play.google.com/store;?hl=ru" target="_blank" rel="noopener noreferrer">
                                <LazyImage 
                                    className="sestrica-mobile__playmarket" 
                                    src={mobile_playmarket} 
                                    alt="Скачать приложение Сестрица в PlayMarket" />
                            </a>
                        </div>
                    </div>
                    <LazyImage 
                        className="sestrica-mobile__image" 
                        src={mobile_phone} 
                        alt="Заказ воды в мобильном приложении Сестрица" />
                </div>
            </div>
            <Switcher prev="/portfolio/portfolio_redKey" next="/portfolio/portfolio_collegeBeast" /> 
        </HelmetProvider>
    )
};

export default SestricaComponent;