import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FrontPage from '../common_blocks/FrontPage/FrontPage';
import Switcher from '../common_blocks/Switcher/Switcher';
import LazyImage from '../../../../utilities/LazyImage/LazyImage';

import '../common_blocks/FrontPage/FrontPage.scss';
import './CollegeBeast.scss';

import logo_college from '../../../../images/college_logo_white.svg';
import macbook_college from '../../../../images/macbook_collegeBeast.png';

import sport_img from '../../../../images/college_sport_ellipse.png';
import uni_img from '../../../../images/college_uni_ellipse.png';
import result_img from '../../../../images/college_result_ellipse.png';
import monitor from '../../../../images/college_monitor.png';
import red_back from '../../../../images/college_red-back.png';
import white_back from '../../../../images/college_white-back.png';
import form_college from '../../../../images/college_form.png';
import enroll_college from '../../../../images/college_design_enroll.png';
import sports_college from '../../../../images/college_design_sports.png';
import uni_college from '../../../../images/college_design_universities.png';
import adaptive_phone from '../../../../images/college_adaptive-phone.png';
import adaptive_grey from '../../../../images/college_adaptive-grey.png';
import adaptive_white from '../../../../images/college_adaptive-white.png';
import adaptive_img1 from '../../../../images/college_adaptive-img1.png';
import adaptive_img2 from '../../../../images/college_adaptive-img2.png';
import adaptive_img3 from '../../../../images/college_adaptive-img3.png';
import adaptive_img4 from '../../../../images/college_adaptive-img4.png';
import adaptive_img5 from '../../../../images/college_adaptive-img5.png';
import adaptive_img6 from '../../../../images/college_adaptive-img6.png';
import adaptive_img7 from '../../../../images/college_adaptive-img7.png';
import adaptive_img8 from '../../../../images/college_adaptive-img8.png';

function CollegeBeast() {
    return(
        <HelmetProvider>
            <Helmet>
                <title>CollegeBeast — yoso.ru</title>
            </Helmet> 
            <div className="college-main">
                <div className="college-main__info">
                    <img src={logo_college} alt="Логотип CollegeBeast. CollegeBeast logo"/>
                    <p className="college-main__text">CollegeBeast</p>
                    <a 
                        href="https://collegebeast.ru/" 
                        className="college-main__link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        collegebeast.ru
                    </a>
                </div>
                <div className="college-main__tablet">
                    <img 
                        className="college-main__image" 
                        src={macbook_college} 
                        alt="Ноутбук с открытой главной страницей сайта по устройству спортсменов в университеты США" />
                </div>
            </div>
            <FrontPage />
            <div className="college-goal">
                <div className="college-goal__text-block">
                    <p className="college-goal__title">Задача</p>
                    <p className="college-goal__text">
                        Спроектировать сайт для компании, помогающей школьникам получить 
                        спортивную стипендию в университетах США.
                    </p>  
                </div>
                <div className="college-goal__stats-block">
                    <div className="college-goal__sport">
                        <LazyImage className="college-goal__sport-img" src={sport_img} alt="Круг с красной обводкой" />
                        <p className="college-goal__stat-text sport_text">33</p>
                        <p className="college-goal__info-text">вида спорта<br/>для стипендии</p>
                    </div>
                    <div className="college-goal__uni">
                        <LazyImage className="college-goal__uni-img" src={uni_img} alt="Круг с фиолетовой обводкой" />
                        <p className="college-goal__stat-text uni_text">1500</p>
                        <p className="college-goal__info-text">университетов<br/> в Америке</p>
                    </div>
                    <div className="college-goal__result">
                        <LazyImage className="college-goal__result-img" src={result_img} alt="Круг с синей обводкой" />
                        <p className="college-goal__stat-text result_text">100</p>
                        <p className="college-goal__info-text">результат<br/> на получение</p>
                    </div>
                </div>
            </div>
            
            <div className="college-design">
                <div>
                    <div className="college-design__text-block">
                        <p className="college-design__title">Дизайн</p>
                        <p className="college-design__text">
                            Для проекта необходимо было придумать яркий образ,
                            который привлечет студентов и расскроет привлекательность 
                            обучения за рубежом
                        </p>
                    </div>
                </div>
                <div className="college-design__monitor-block">
                    <div>
                        <LazyImage 
                            className="college-design__monitor" 
                            src={monitor} 
                            alt="Монитор, на котором открыта страница сайта по получению консультации на поступление в американский университет" />
                        <LazyImage 
                            className="college-design__red-back" 
                            src={red_back} 
                            alt="Красный фон в форме прямоугольника с закругленными углами" />
                        <LazyImage 
                            className="college-design__white-back" 
                            src={white_back} 
                            alt="Белый фон в форме прямоугольника с закругленными углами" />
                    </div>
                </div>
                <div className="college-design__achieve-block">
                    <LazyImage 
                        className="college-design__sports" 
                        src={sports_college} 
                        alt="Виды спорты с помощью которых можно поступить в американский университет" />
                    <LazyImage 
                        className="college-design__enroll" 
                        src={enroll_college} 
                        alt="Оценка перспектив спорстмена, как поступить в университет США" />
                    <LazyImage 
                        className="college-design__universities" 
                        src={uni_college} 
                        alt="Университеты Америки, которые участвуют в программе по зачислению студентов, имеющих успехи в спортивной сфере" />
                    <LazyImage 
                        className="college-design__form" 
                        src={form_college} 
                        alt="Форма заполнения заявления для получения консультации на сайте CollegeBeast" />
                </div>
            </div>
            
            <div className="college-adaptive">
                <div className="college-adaptive__text-block">
                    <p className="college-adaptive__title">Адаптив</p>
                    <p className="college-adaptive__text">
                        Целевая аудитория сайта - активные пользователи мобильных устройств. 
                        Но принятие решения о поступлении в американский университет - серьезное решение, 
                        требующее привлечения родителей и более вероятного использования обычных компьютеров. 
                        Поэтому мобильная версия была облегчена по контенту и функционалу, основной упор делался на desktop-версию сайта.
                    </p>
                </div>
                <div className="college-adaptive__phone-block">
                    <LazyImage 
                        className="college-adaptive__grey" 
                        src={adaptive_grey} 
                        alt="Серый фон на всю страницу" />
                    <LazyImage 
                        className="college-adaptive__white" 
                        src={adaptive_white} 
                        alt="Белый фон в форме прямоугольника с закругленными углами" />
                    <LazyImage 
                        className="college-adaptive__phone" 
                        src={adaptive_phone} 
                        alt="Телефон на экране которого информация о получении консультации для поступления в университет США" />
                </div>
                <div className="college-adaptive__articles-block">
                    <LazyImage 
                        className="college-adaptive__img1" 
                        src={adaptive_img1} 
                        alt="Блог портала CollegeBeast" />
                    <LazyImage 
                        className="college-adaptive__img2" 
                        src={adaptive_img2} 
                        alt="Список университетов США с информацией о поступлении для иностранных студентов" />
                    <LazyImage 
                        className="college-adaptive__img3" 
                        src={adaptive_img3} 
                        alt="Контакты сайта для получения академической и спортивной стипендии" />
                    <LazyImage 
                        className="college-adaptive__img4" 
                        src={adaptive_img4} 
                        alt="Гайд по поступлению в американский вуз" />
                    <LazyImage 
                        className="college-adaptive__img5" 
                        src={adaptive_img5} 
                        alt="Информация о поступлении в University of British Columbia" />
                    <LazyImage 
                        className="college-adaptive__img6" 
                        src={adaptive_img6} 
                        alt="Виды спорта, по которым можно поступить в американский вуз" />
                    <LazyImage 
                        className="college-adaptive__img7" 
                        src={adaptive_img7} 
                        alt="F.A.Q. Часто задаваемые вопросы о поступлении в университет США" />
                    <LazyImage 
                        className="college-adaptive__img8" 
                        src={adaptive_img8} 
                        alt="Карта США с выделенной символом Университет Техас в Остин" />
                </div>
            </div>
            <Switcher prev="/portfolio/portfolio_sestrica" next="/portfolio/portfolio_h2u" /> 
        </HelmetProvider>
    )
};

export default CollegeBeast;