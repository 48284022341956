import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ModalInfo from '../../Modal/Modal';

import './CarouselComponent.scss';

// import slide_1 from '../../../images/main_slider_1.png';
// import slide_2 from '../../../images/main_slider_2.png';
// import slide_3 from '../../../images/main_slider_3.png';
import slide_4 from '../../../images/main_slider_4.png';

function CarouselComponent(){
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className="main">
                <div className="main-info__text-block">
                    <div className="main-info__title">
                            <span className="main-info__title-bold">Автоматизация </span> 
                            <br/>службы доставки воды
                    </div>
                    <div className="main-info__subtitle">
                        <span className="main-info__subtitle-text">Комплексное IT решение</span>
                    </div>
                    <div className="main-info__button-block">
                        <Button className="bem-button" onClick={() => setModalShow(true)}>
                            <span className="bem-button__text">Cвязаться с нами</span>
                        </Button>
                    </div>
                </div>
                <div className="main-info__media">
                <img 
                    className="main-info__slides4-img" 
                    src={slide_4} 
                    alt="YOSO логотип с иконками CRM, машины для доставки воды, покупки товаров и мобильного приложения"
                />
                    {/* <Carousel slide={false} controls={false} interval={null}>
                        <Carousel.Item>
                            <div className="main-info__slides-block">
                                <img 
                                    className="main-info__slides-img" 
                                    src={slide_1} 
                                    alt="Карта с планом рейсов и мобильное приложение со списком заказов" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="main-info__slides-block">
                                <img 
                                    className="main-info__slides-img" 
                                    src={slide_2} 
                                    alt="Официальный сайт магазина доставки воды. Вода из Марий Эл" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="main-info__slides-block">
                                <img 
                                    className="main-info__slides3-img" 
                                    src={slide_3} 
                                    alt="Машина для доставки воды и детали заказы в мобильном приложении" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="main-info__slides-block">
                                <img 
                                    className="main-info__slides4-img" 
                                    src={slide_4} 
                                    alt="YOSO логотип с иконками CRM, машины для доставки воды, покупки товаров и мобильного приложения" />
                            </div>
                        </Carousel.Item>
                    </Carousel>                 */}
                </div>
            </div>
            <ModalInfo show={modalShow} onHide={() => setModalShow(false)}/>
        </>
    )
}

export default CarouselComponent;