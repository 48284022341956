import React from 'react';
import { Media } from 'react-bootstrap';

import LazyImage from '../../../utilities/LazyImage/LazyImage';

import './AdvantagesComponent.scss';

import call_centre from '../../../images/main_advantages_call_centre.png';
import logistic from '../../../images/main_advantages_logistic.png';
import crm_heart from '../../../images/main_advantages_crm.png';
import delivery from '../../../images/main_advantages_delivery.png';

function AdvantagesComponent() {
    return (
        <>
            <div className="advantages-block">
                <div className="advantages-block__title">Преимущества</div>
                <div className="media-container">
                    <Media className="media-block-left">
                        <LazyImage className="media-block__image-left" src={call_centre} alt="Девушка из call-центра отвечает на звонок" />
                        <Media.Body className="media-block__body">
                            <div className="media-block__body-content">
                                <div className="media-block__body-header">Сэкономьте 50% на call-центре</div>
                                <div className="media-block__body-text">
                                    Оформляйте до 75% заказов без участия операторов, 
                                    используя YOSO.CRM и робота. Подключите WhatsApp, Viber, 
                                    ВКонтакте, Facebookи другие цифровые каналы и принимайте заказы
                                    из удобного клиенту канала связи. 
                                    <br/><br/>
                                    Клиент оформит заказ за 2 минуты по удобному каналу связи, не дожидаясь ответа диспетчера. 
                                    Все заказы сразу попадают в единый чат, который легко обрабатывать. 
                                    После этого история общения передается в профиль клиента в CRM.
                                </div>
                            </div>
                        </Media.Body>
                    </Media>
                    <hr className="line-break"/>
                    <Media className="media-block-right">
                        <LazyImage className="media-block__image-right" src={crm_heart} alt="CRM для полной автоматизации обслуживания" />
                        <Media.Body className="media-block__body">
                            <div className="media-block__body-content">
                                <div className="media-block__body-header">Сердце клиентского обслуживания</div>
                                <div className="media-block__body-text">
                                    CRM содержит всю информацию о клиентах и заказах, 
                                    необходимую для полной автоматизации их бслуживания. 
                                    Все звонки, письма, чаты с клиентами на сайте и в соцсетях сохраняются 
                                    в едином профиле клиента. Ни один заказ не будет потерян. 
                                    <br/><br/>
                                    Прозрачная система взаиморасчетов, интегрированная с 1С, 
                                    покажет является ли клиент должником, в том числе по оборотной таре.
                                </div>
                            </div>
                        </Media.Body>     
                    </Media>
                    <hr className="line-break"/>
                    <Media className="media-block-custom">
                        <LazyImage className="media-block__custom-image" src={delivery} alt="Навигатор, составление оптимального маршрута для доставки воды" />
                        <Media.Body className="media-block__body">
                            <div className="media-block__body-content">
                                <div className="media-block__body-header">Упрощение доставки</div>
                                <div className="media-block__body-text">
                                    Все задания из маршрутного листа автоматически отправляются 
                                    на мобильное приложение водителя. Полученные задания распределены 
                                    по оптимальному маршруту. 
                                    <br/><br/>
                                    Водитель в онлайн-режиме получает уведомления 
                                    обо всех изменениях и новых заданиях прямо на свой смартфон.
                                </div>
                            </div>
                        </Media.Body>
                    </Media>
                    <hr className="line-break"/>
                    <Media className="media-block-right">
                    <LazyImage className="media-block__image-right" src={logistic} alt="Человек оставляет отзыв о заказе через телефон" />
                        <Media.Body className="media-block__body">
                            <div className="media-block__body-content">
                                <div className="media-block__body-header custom-header">Сэкономьте 15% на логистике</div>
                                <div className="media-block__body-text">
                                    Алгоритм оптимизации маршрутов сократит длину маршрута, 
                                    время доставки и количество автомобилей, 
                                    повысит эффективность их загрузки. 
                                    <br/><br/>
                                    Больше никакой ручной работы логиста. 
                                    В случае непредвиденной остановки одного из автомобилей вы можете пересчитать существующие маршруты. 
                                    Клиент заранее узнает точное время доставки.
                                </div>
                            </div>
                        </Media.Body>
                    </Media>
                </div>
            </div>
        </>
    )
};

export default AdvantagesComponent;