import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FrontPage from '../common_blocks/FrontPage/FrontPage';
import Switcher from '../common_blocks/Switcher/Switcher';
import LazyImage from '../../../../utilities/LazyImage/LazyImage';
import './RedKey.scss';

import logo_sestrica from '../../../../images/sestrica_logo_white.svg';
import macbook_redkey from '../../../../images/redkey_main_macbook.png';
import design_image1 from '../../../../images/redkey_design_image1.png';
import design_image2 from '../../../../images/redkey_design_image2.png';
import design_image3 from '../../../../images/redkey_design_image3.png';
import design_image4 from '../../../../images/redkey_design_image4.png';
import design_image5 from '../../../../images/redkey_design_image5.png';

function RedKey() {
    return(
        <HelmetProvider>
            <Helmet>
                <title>Красный ключ — yoso.ru</title>
            </Helmet> 
            <div className="redkey-main">
                <div className="redkey-main__info">
                    <img src={logo_sestrica} alt="Логотип интернет магазина Красный Ключ" />
                    <p className="redkey-main__text">Вода "Красный ключ"</p>
                    <a 
                        href="https://krasniykluch.ru/" 
                        className="redkey-main__link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        krasniykluch.ru
                    </a>
                </div>
                <div className="redkey-main__tablet">
                    <img 
                        className="redkey-main__image"
                        src={macbook_redkey} 
                        alt="Ноутбук с главной страницей сайта по доставке воды Красный ключ" />
                </div>
            </div>
            <FrontPage />

            <div className="redkey-goal">
                <div className="redkey-goal__text-block">
                    <p className="redkey-goal__title">Задача</p>
                    <p className="redkey-goal__text">
                        Обновить внешний вид и проработать обновленный функционал
                        для крупного поставщика природной питьевой воды из Уфы
                    </p>
                </div>
            </div>
            <div className="redkey-design">
                <div className="redkey-design__text-block">
                    <p className="redkey-design__title">Дизайн</p>
                </div>
                <div className="redkey-design__image-block">
                    <LazyImage 
                        className="redkey-design__image1" 
                        src={design_image1} 
                        alt="Новый дизайн интернет-магазина Красный ключ. Главная страница. Природная вода" />
                    <LazyImage 
                        className="redkey-design__image3" 
                        src={design_image3} 
                        alt="Темно-синий квадрат с закругленными углами" />
                    <LazyImage 
                        className="redkey-design__image2" 
                        src={design_image2} 
                        alt="Заказ по акции: вода Красный ключ, помпа Lilu и бутыль поликарбоновая по" />
                    <LazyImage 
                        className="redkey-design__image4" 
                        src={design_image4} 
                        alt="Преимущества воды в бутылях Красный ключ" />
                    <LazyImage 
                    className="redkey-design__image5" 
                    src={design_image5} 
                    alt="Санитарная обработка/чистка кулеров в сервисном центре" />
                </div>
            </div>

            <Switcher prev="/portfolio/portfolio_h2u" next="/portfolio/portfolio_sestrica"/>
        </HelmetProvider>
    );
}

export default RedKey;