import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ProjectCard from './ProjectCard/ProjectCard';
import './Projects.scss';

import card_sestrica from '../../images/projects_card_sestrica.png';
import card_h2u from '../../images/projects_card_h2u.png';
import card_redKey from '../../images/projects_card_redKey.png';
import card_collegeBeast from '../../images/projects_card_collegeBeast.png';

function Projects() {
    return(
        <HelmetProvider>
            <Helmet>
                <title>Портфолио сайтов — yoso.ru</title>
            </Helmet> 
            <div className="projects__block">
                <div className="projects__text-block">
                    <p className="projects__title">Портфолио</p>
                    <p className="projects__text">Создаём эффективные коммерческие сайты</p>
                </div>
                <ProjectCard 
                    link="/portfolio/portfolio_sestrica"
                    image={card_sestrica}
                    alt="Девушка пьет воду из прозрачного стакана"
                    bg_classname="bg-sestrica"
                    title="Сестрица" 
                    text="Интернет-магазин популярной воды в Поволжье. 
                        Продумали обвновленный интерфейс и переработали функционал"
                    lazy={false}
                />
                <ProjectCard 
                    link="/portfolio/portfolio_collegeBeast"
                    image={card_collegeBeast}
                    alt="Футбольные команды выстроились перед матчем"
                    bg_classname="bg-college"
                    title="CollegeBeast"
                    text="Разработали дизайн компании, помогающей школьникам 
                        получить спортивную стипендию в университетах США."
                    lazy={true}
                />

                <ProjectCard 
                    link="/portfolio/portfolio_h2u"
                    image={card_h2u}
                    alt="Генератор водорода и бутылка воды"
                    bg_classname="bg-h2u"
                    title="H2U"
                    text="E-commerce площадка для продажи оборудования для производcтва 
                        водородной воды. Полностью обновили дизайн."
                    lazy={true}
                />

                <ProjectCard 
                    link="/portfolio/portfolio_redKey"  
                    image={card_redKey}
                    alt="Родник Красный Ключ в Наримановском районе Башкортостана"
                    bg_classname="bg-redKey"
                    title="Красный Ключ"
                    text="Одна из самых популярных вод Уфы. 
                        Разработали дизайн и интернет-магазин."
                    lazy={true}
                />
            </div>
        </HelmetProvider>
    )
}

export default Projects;