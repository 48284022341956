import React from 'react';

import CarouselComponent from './CarouselComponent/CarouselComponent';
import BusinessComponent from './BusinessComponent/BusinessComponent';
import AdvantagesComponent from './AdvantagesComponent/AdvantagesComponent';
import PartnershipComponent from './PartnershipComponent/PartnershipComponent';

function MainPage() {
    return(
        <>  
            <CarouselComponent />
            <BusinessComponent />
            <AdvantagesComponent />
            <PartnershipComponent />
        </>
    )
}

export default MainPage;