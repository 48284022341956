import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ModalInfo from '../Modal/Modal';
import './Footer.scss';

import footer_mail from '../../images/footer_mail.png';

function Footer() {
    const [modalShow, setModalShow] = useState(false);

    return(
        <>
            <div className="footer-panel">
                <div className="footer__title">
                    Контакты
                </div>
                <div className="footer__contact-block">
                    <div className="footer__contact-item">
                        <a href="mailto:info@yoso.ru">
                            <img className="footer__image" src={footer_mail} alt="Иконка почты" />
                            <span className="footer__text">info@yoso.ru</span>
                        </a>
                    </div>
                    <div className="footer__button-item">
                        <Button className="bem-button" onClick={() => setModalShow(true)}>
                            <span className="bem-button__text">Связаться с нами</span>
                        </Button>
                    </div>
                </div>
            </div>
            <ModalInfo show={modalShow} onHide={() => setModalShow(false)}/>
        </>
    )
}

export default Footer;