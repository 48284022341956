import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Hamburger from 'hamburger-react';

import './Header.scss';

import blue_logo from '../../images/blue_yoso.svg';
import white_logo from '../../images/white_yoso.svg';

function Header(props) {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const handleNavCollapse = () => {
        setIsNavExpanded(!isNavExpanded);
    }

    const path =  props.location.pathname.slice(1);
    let image_logo = white_logo;
    let toggler_color = "#FFFFFF";
    let toggler_border = "custom-border";
    let item_style = "";
    let nav_style = "";

    switch(path) {
        case "portfolio/portfolio_sestrica":
            item_style = "sestrica_navbar-item";
            break;
        case "portfolio/portfolio_collegeBeast":
            item_style = "college_navbar-item";
            break;
        case "portfolio/portfolio_h2u":
            item_style = "h2u_navbar-item";
            break;
        case "portfolio/portfolio_redKey":
            item_style = "redkey_navbar-item";
            break;
        default:
            image_logo = blue_logo;
            toggler_color = "#3E7EE3";
            toggler_border = "common-border";
            nav_style = "common-nav";

    }

    return (
        <>  
            <Navbar className="nav-panel" expand="md" expanded={isNavExpanded}>
                <Navbar.Brand className="nav-panel__logo mr-auto" >
                    <a href="/">
                        <img className="nav-panel__blue-logo" src={blue_logo} alt="YOSO логотип Комплексное IT-решение для автоматизации службы доставки воды"/>
                        <img className="nav-panel__white-logo" src={image_logo} alt="YOSO логотип Комплексное IT-решение для автоматизации службы доставки воды"/>
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle
                    className={`nav-toggler ${toggler_border}`}  
                    onClick={() => handleNavCollapse()}
                >
                    <Hamburger
                        toggled={isNavExpanded} 
                        toggle={() => handleNavCollapse()}
                        size={30}
                        color={toggler_color} />
                </Navbar.Toggle>
                <Navbar.Collapse className="nav-collapse">
                    <Nav className={`nav-menu ${nav_style}`}>
                        <NavLink 
                            onClick={() => handleNavCollapse()} 
                            to="/portfolio" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Портфолио сайтов
                        </NavLink>
                        <NavLink 
                            onClick={() => handleNavCollapse()}
                            to="/logistics" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Автоматизация логистики
                        </NavLink>
                        <NavLink 
                            onClick={() => handleNavCollapse()}
                            to="/contacts" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Контакты
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {/* <header>
            <nav className="nav-panel navbar navbar-expand-md">
                <div className="nav-panel__logo mr-auto navbar-brand" >
                    <a href="/">
                        <img className="nav-panel__blue-logo" src={blue_logo} alt="YOSO логотип Комплексное IT-решение для автоматизации службы доставки воды"/>
                        <img className="nav-panel__white-logo" src={image_logo} alt="YOSO логотип Комплексное IT-решение для автоматизации службы доставки воды"/>
                    </a>
                </div>
                <button 
                    type="button"
                    className={`nav-toggler navbar-toggler ${border_toggler}`}  
                    // onClick={() => handleNavCollapse()}
                    data-toggle="collapse"
                    data-target="toggle-navbar"
                    aria-controls="toggle-navbar"
                    aria-expanded="false"
                >
                    <span className={`navbar-toggler-icon ${toggler}`} />
                </button>
                <div className="nav-collapse collapse navbar-collapse" id="toggle-navbar">
                    <Nav className="nav-menu">
                        <NavLink 
                            onClick={() => handleNavCollapse()} 
                            to="/projects" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Портфолио сайтов
                        </NavLink>
                        <NavLink 
                            onClick={() => handleNavCollapse()}
                            to="/products" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Автоматизация логистики
                        </NavLink>
                        <NavLink 
                            onClick={() => handleNavCollapse()}
                            to="/contacts" 
                            activeClassName="nav-menu__item_active" 
                            className={`nav-menu__item ${item_style}`}
                        >
                            Контакты
                        </NavLink>
                    </Nav>
                </div>
            </nav>
        </header> */}
        </>
    )
}

export default withRouter(Header);