import React from 'react';

import './Alert.scss';

function Alert() {
    return (
        <>
            <div className="alert__info-block">
                <div className="alert__info-text">
                    Основное требование заказчика - сделать интернет-магазин более простым и дружелюбным, 
                    сместить акценты на товары компании и акции
                </div>
            </div>
        </>
    )
}

export default Alert;