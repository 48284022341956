import React from 'react';
import { Button } from 'react-bootstrap';

import './PartnershipComponent.scss';

function PartnershipComponent() {
    return(
        <>
            <div className="logistic-block">
                <div className="dev-bg">
                    <div className="dev-main">
                        <div className="dev__text-block">
                            <div className="dev__title">Разрабатываем<br/>систему логистики</div>                    
                            <a href="/logistics">
                                <Button className="bem-button dev__button">
                                    <span className="dev__button-text">Подробнее</span>
                                </Button>         
                            </a>
                        </div>               
                    </div>
                </div>
                <div className="collab-bg">
                    <div className="collab-main">
                        <div className="collab__title">С нами сотрудничают</div>
                        <div className="collab__items-menu">
                            <div className="collab__item">
                                <a href="https://voda-sestrica.ru/" target="_blank" rel="noopener noreferrer">
                                    <div className="collab__sestrica-img"></div>
                                </a>
                            </div>
                            <div className="collab__item">
                                <a href="https://krasniykluch.ru/" target="_blank" rel="noopener noreferrer">
                                    <div className="collab__redKey-img"></div>
                                </a>
                            </div>
                            <div className="collab__item">
                                <a href="https://h2u.ru/" target="_blank" rel="noopener noreferrer">
                                    <div className="collab__h2u-img"></div>
                                </a>
                            </div>
                            <div className="collab__item">
                                <a href="https://collegebeast.ru/" target="_blank" rel="noopener noreferrer">
                                    <div className="collab__college-img"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnershipComponent;