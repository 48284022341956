import React from 'react';

import LazyImage from '../../../utilities/LazyImage/LazyImage';
import './ProjectCard.scss';

function Projects(props) {
    return(
        <>
            <div className="project-card">
                <a href={props.link}>
                    {props.lazy ? 
                        <LazyImage 
                            className="project-card__img"
                            src={props.image} 
                            alt={props.alt}
                        />
                    :
                        <img
                            className="project-card__img" 
                            src={props.image} 
                            alt={props.alt}
                        /> 
                    }
                    <div className={`project-card__background ${props.bg_classname}`}>
                        <p className="project-card__title">{props.title}</p>
                    </div>
                </a>
                <p className="project-card__text">
                   {props.text}
                </p>
            </div>
        </>
    )
}

export default Projects;