import React from 'react';

import './Switcher.scss';

import previous_project from '../../../../../images/sestrica_switch_prev.png';
import next_project from '../../../../../images/sestrica_switch_next.png';

function Switcher(props){
    return(
        <>
            <div className="switcher">
                <div className="switcher__button-block">
                    <div>
                        <a href={props.prev}>
                            <img 
                                className="switcher__image" 
                                src={previous_project} 
                                alt="Синяя стрелка назад. Синяя стрелка влево" />
                            <p className="switcher__text">Предыдущий проект</p>
                        </a>
                    </div>
                    <div className="switcher__bottom">
                        <a href={props.next}>
                            <p className="switcher__text">Следующий проект</p>
                            <img 
                                className="switcher__image" 
                                src={next_project} 
                                alt="Синяя стрелка вперед. Синяя стрелка вправо" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Switcher;