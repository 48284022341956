import React from 'react';

import { Modal } from 'react-bootstrap';
import ContactForm from '../../utilities/ContactForm/ContactForm';
import './Modal.scss';


class ModalInfo extends React.Component{
    constructor(props){
        super(props);
        this.ModalRef = React.createRef();
        this.state = {
            username: '',
            phone: '',
            position: '',
            email: '',
            company: '',
            info: '',
            errors: {
                email: null,
                username: null
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.errors.username === ""
            && this.state.errors.email === ""){
                // alert(JSON.stringify(this.state));
                this.resetForm();
                this.props.onHide();
            }
        else {
            this.validateEmail();
            this.validateName();
        }
    }

    resetForm = () => {
        this.setState({
            username: '',
            phone: '',
            position: '',
            email: '',
            company: '',
            info: '',
            errors: {
                email: null,
                username: null
            }
        });
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, () => {
            switch(name){
                case 'username':
                    this.validateName();
                    break;
                case 'email':
                    this.validateEmail();
                    break;
                default:
                    break;
            }
        })
    }

    validateName = () => {
        const nameValid = this.state.username.length >= 3;
        const nameError = nameValid ? "" : 'Минимальное количество символов: 3';
        setTimeout(() => {
            this.setState({
                ...this.state, 
                errors: {
                    ...this.state.errors,
                    username: nameError
                }
            })
        }, 10)
    }
    
    validateEmail = () => {
        const value = this.state.email;
        let emailValid = value.length >= 3;
        let emailError = emailValid ? "" : 'Минимальное количество символов: 3';
        if (emailValid){
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            emailError = emailValid ? "" : 'Введите правильные данные';
        }
        setTimeout(() => {
            this.setState({
                ...this.state, 
                errors: {
                    ...this.state.errors,
                    email: emailError,
                }
            })
        }, 10)
    }

    closeEvent = () =>{
        this.props.onHide();
        this.resetForm();
    }

    render() {
        return (
            <>
                <Modal
                // animation={false}
                ref={this.ModalRef}
                dialogClassName="modal-info"
                {...this.props}
                centered
                aria-labelledby="modal-info">
                    <div className="modal-info__content">
                        <Modal.Header className="modal-info__header">
                            <Modal.Title
                                className="modal-info__title" 
                                id="modal-info"
                            >
                                Давайте знакомиться
                            </Modal.Title> 
                            <Modal.Title 
                                as="span" 
                                className="modal-info__subtitle">
                                Мы свяжемся с вами и подробно ответим на вопросы о наших продуктах и услугах.
                            </Modal.Title>     
                        </Modal.Header>
                        <Modal.Body>
                            <ContactForm modalForm={true} onHide={this.props.onHide} />
                        </Modal.Body>
                    </div>
                </Modal>
            </>
        )
    }
} 

export default ModalInfo;