import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './components/Header/Header';
import MainPage from './components/MainPage/MainPage';
import Contact from './components/Contacts/Contacts';
import Products from './components/Products/Products';
import Projects from './components/Projects/Projects';
import Footer from './components/Footer/Footer';
import Sestrica from './components/Projects/Portfolio/Sestrica/Sestrica';
import CollegeBeast from './components/Projects/Portfolio/CollegeBeast/CollegeBeast';
import H2U from './components/Projects/Portfolio/H2U/H2U';
import RedKey from './components/Projects/Portfolio/RedKey/RedKey';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route exact path="/portfolio">
            <Projects />
          </Route>
          <Route path="/logistics">
            <Products />
          </Route>
          <Route path="/contacts">
            <Contact />
          </Route>
          <Route path="/portfolio/portfolio_sestrica">
            <Sestrica />
          </Route>
          <Route path="/portfolio/portfolio_collegeBeast">
            <CollegeBeast />
          </Route>
          <Route path="/portfolio/portfolio_h2u">
            <H2U />
          </Route>
          <Route path="/portfolio/portfolio_redKey">
            <RedKey />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
