import React from 'react';
import { Card, CardDeck, Badge } from 'react-bootstrap';

import LazyImage from '../../../utilities/LazyImage/LazyImage';

import './BusinessComponent.scss';

import logisticCard from '../../../images/logisticCard.png';
import crmCard from '../../../images/crmCard.png';
import websiteCard from '../../../images/websiteCard.png';
import logistic_bottom from '../../../images/logistic_bottom.png';
import crm_bottom from '../../../images/crm_bottom.png';
import logistic_top from '../../../images/logistic_top.png';
import website_top from '../../../images/website_top.png';

function BusinessComponent() {
    return (
        <>
            <div className="main-business-back">
                <div className="main-business">
                    <div className="main-title">Помогаем развиваться<br/> вашему бизнесу</div>
                    <CardDeck className="deck-container">
                        <Card className="custom-card">
                            <div className="custom-card__image-container">
                                <LazyImage className="crm-bottom" src={crm_bottom} alt="голубой полукруг для заднего фона" />
                                <LazyImage className="custom-card__image" variant="top" src={crmCard} alt="доска CRM c инфографикой" />
                            </div>
                            <Card.Body>
                                <Card.Title className="main-text custom-card__title">
                                    CRM
                                    <Badge className="custom-badge">
                                        <span className="custom-badge__text">скоро</span>
                                    </Badge>
                                </Card.Title>
                                <Card.Text className="main-text custom-card__text">
                                    Сердце автоматизации и клиентского обсуживания
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <a className="custom-card__redirect" href="/logistics">
                            <Card className="custom-card">
                                <div className="custom-card__image-container">
                                    <LazyImage className="logistic-bottom" src={logistic_bottom} alt="синий полукруг для заднего фона"/>
                                    <LazyImage className="logistic-top" src={logistic_top} alt="голубой полукруг для бэкграунда" />
                                    <LazyImage className="custom-card__image image-shadow" variant="top" src={logisticCard} alt="Карта с планом рейсов для оптимизации маршрутов доставки воды" />
                                </div>
                                <Card.Body>
                                    <Card.Title className="main-text custom-card__title">Logistic</Card.Title>
                                    <Card.Text className="main-text custom-card__text">Управление доставкой и оптимизация маршрутов</Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                        <a className="custom-card__redirect" href="/portfolio">
                            <Card className="custom-card">
                                <div className="custom-card__image-container">
                                    <LazyImage className="website-top" src={website_top} alt="светло-синий полукруг для заднего фона" />
                                    <LazyImage className="custom-card__image image-shadow" variant="top" src={websiteCard} alt="Официальный сайт, интернет-магазин доставки воды Красный ключ" />
                                </div>
                                <Card.Body>
                                    <Card.Title className="main-text custom-card__title">Сайт и приложение</Card.Title>
                                    <Card.Text className="main-text custom-card__text">Интернет-магазин и мобильное приложение вашей компании</Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </CardDeck>
                </div>
            </div>
        </>
    )
}

export default BusinessComponent;